import React, { ReactElement, useEffect } from 'react';

import EhubLogout from './EhubLogout';

declare global {
    // eslint-disable-next-line @typescript-eslint/interface-name-prefix
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        SandTechHub: any;
    }
}

interface IProps {
    sdkJsUrl: string;
    logoutUrl: string;
    csrfToken: string;
    redirectUrl: string;
}

export default function EhubHeader({
    sdkJsUrl,
    logoutUrl,
    csrfToken,
    redirectUrl,
}: IProps): ReactElement {
    useEffect(() => {
        const loadSDK = async (): Promise<void> => {
            try {
                const script = document.createElement('script');
                script.type = 'module';
                script.src = sdkJsUrl;

                script.onload = (): void => {
                    const SandTechHub = window.SandTechHub;
                    const ehub = new SandTechHub.SDK('savanna');
                    ehub.mountHeader('ehub-sdk');
                };

                const sdkDiv = document.getElementById('ehub-sdk');
                if (sdkDiv) {
                    sdkDiv.appendChild(script);
                }
            } catch (error) {
                throw new Error('Failed to load the SandTechHub SDK: ' + error);
            }
        };

        loadSDK();
    }, []);

    return (
        <div className="w-full">
            <div className="sdk" id="ehub-root">
                <div id="ehub-sdk"></div>
                <EhubLogout
                    csrfToken={csrfToken}
                    logoutUrl={logoutUrl}
                    redirectUrl={redirectUrl}
                />
            </div>
        </div>
    );
}
