import { ReactElement, useEffect } from 'react';

const getCookieValue = (cookieName: string): string | null => {
    const match = document.cookie.match(
        new RegExp('(?:^|; )' + cookieName + '=([^;]*)'),
    );
    return match ? decodeURIComponent(match[1]) : null;
};

const sendLogoutRequest = async (
    logoutUrl: string,
    csrfToken: string,
    redirectUrl: string,
): Promise<void> => {
    const response = await fetch(logoutUrl, {
        credentials: 'include',
        headers: {
            'X-CSRF-Token': csrfToken,
        },
        method: 'DELETE',
    });

    if (response.ok) {
        window.location.replace(redirectUrl);
    }
};

interface IEhubLogoutProps {
    logoutUrl: string;
    csrfToken: string;
    redirectUrl: string;
}

export default function EhubLogout({
    logoutUrl,
    csrfToken,
    redirectUrl,
}: IEhubLogoutProps): ReactElement {
    useEffect(() => {
        const targetDiv = document.getElementById('ehub-sdk');
        if (!targetDiv) {
            return;
        }

        let previousValue = getCookieValue('eHubLoggedIn');

        const mutationCallback: MutationCallback = () => {
            const currentValue = getCookieValue('eHubLoggedIn');
            if (
                previousValue === 'true' &&
                (currentValue === 'false' || currentValue === null)
            ) {
                sendLogoutRequest(logoutUrl, csrfToken, redirectUrl);
            }
            previousValue = currentValue;
        };

        const observer = new MutationObserver(mutationCallback);
        observer.observe(targetDiv, {
            attributes: true,
            childList: true,
            subtree: true,
        });

        return (): void => {
            observer.disconnect();
        };
    }, []);

    return null;
}
